import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const wichtige_links = ({data}) => (
  <Layout>
    <SEO title="Wichtige Links"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="WICHTIGE LINKS" motto={data.allSanitySiteSettings.nodes[0].motto}/>
      <ElternInfoSubmenu/>

      <article id="con_2_rechts">
        <h3 className="small_sub">
          <span style={{ color: "#007c34" }}>WICHTIGE LINKS</span>
        </h3>

        <h4>Derzeit keine Links.</h4>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default wichtige_links
